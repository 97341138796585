import React, { Component } from 'react';

import { ApiHelper } from '../../helpers/ApiHelper';
import { AppHelper } from '../../../helpers/AppHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../Controller';

import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { UiHelper } from '../../helpers/UiHelper';
import { CustomProductsStyles } from '../../../styles/CustomStyles';
import { Courses } from './Courses';
import { CommonStyles } from '../../../styles/Styles';

export class OrgCourses extends BaseEntityPage {
  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('courses')
    this.pageKey = 'courses'
    this.addNewLabel = 'Add'
    this.orgId = (Controller.get().userMgr().isProductSU() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    if (Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU() || Controller.get().roleHelper().isOrgManagerUser()) {
      this.breadCrumbs = [
        { title: Helper.getString('home'), to: this.baseRoute() },
        { title: this.pageTitle },
      ]
    } else {
      this.breadCrumbs = []
    }
    this.toFetch = [
      { method: 'GET', paths: ['miscs', 'courses'], queryParams: { orgId: this.orgId, pb: 'fieldDefNew', allvisible: true } },
      { method: 'GET', paths: ['miscs', 'globalcourses'] },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return <span className={css(Styles.valueText)}>{current.__index + 1}</span> },
      Info: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.title}</span><br />
            <span className={css(Styles.spanValue)}>{(current.mode === 'ADOPT' ? 'REUSED' : 'CREATED') + ' | ' + Helper.formatTimestamp(current.createdTs)}</span>
          </div>
        )
      },
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }

    if (Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU() || Controller.get().roleHelper().isOrgCreatorUser()) {
      this.headerActions = [
        {
          label: 'Library',
          onClick: () => Controller.get().pushRoute('/admin/orgs/' + this.orgId + '/catalog'),
          icon: 'menu_book'
        },
        {
          label: 'Add',
          onClick: () => this.openModalForAdd()
        }
      ]
    } else {
      this.headerActions = []
      this.hideFormAction = true
    }
  }

  onFetchSuccess(results, options) {
    const { fetchNext } = options || {}
    const courseData = results[0]
    this.formDefinition = courseData.fieldDefNew
    UiHelper.populateOptions(courseData.fieldDefNew, { items: results[1].items }, 'courseId')
    this.handleNextpage(results[0])
    const items = fetchNext ? this.state.items.concat(results[0].items) : results[0].items
    let mItems = []
    if (Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) {
      mItems = items
    } else if (Controller.get().roleHelper().isOrgManagerUser()) {
      for (const i of items) {
        if ((!i.discoveryMode || i.discoveryMode === 'OPEN') && i.state === 'LIVE') {
          mItems.push(i)
        }
      }
    } else if (Controller.get().roleHelper().isOrgCreatorUser()) {
      const appUser = Controller.get().userMgr().getAppUser()
      const userId = appUser.userId
      for (const i of items) {
        if(i.discoveryMode === 'OPEN') {
          mItems.push(i)
          continue
        }
        if (i.ownerIds && i.ownerIds.indexOf(userId) > -1 || i.authorIds && i.authorIds.indexOf(userId) > -1) {
          mItems.push(i)
        }
      }
    }

    this.setState({
      items: mItems
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['miscs', 'mergedcourse-creation'], {}),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['orgcourses', current.id], {}),
      current: current
    });
  }

  openModalForAdd(fieldDef) {
    const fields = []
    this.formDefinition.steps[0].fields.forEach(i => { if (i.key === 'title') fields.push(i) })
    delete fields[0].condition
    fields[0].label = Helper.getString('title')
    super.openModalForAdd({ steps: [{ fields }] })
  }

  openModalForDelete(current, fieldDef) {
    const fields = []
    this.formDefinition.steps[0].fields.forEach(i => { if (i.key === 'title') fields.push(i) })
    delete fields[0].condition
    super.openModalForDelete(current, { steps: [{ fields }] })
  }

  onActions = (current) => {
    const actions = []
    return actions
  }

  onMenuItems(current) {
    const actions = []
    let menuItems = []
    if (Controller.get().roleHelper().isOrgCreatorUser() || Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) {
      const appUser = Controller.get().userMgr().getAppUser()
      const userId = appUser.userId
      if (current.ownerIds && current.ownerIds.indexOf(userId) > -1 || Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) {
        menuItems = [
          {
            current: current,
            key: current.id + '-details',
            link: this.baseRoute() + '/orgcourses/' + current.id + '/details',
            title: Helper.getString('edit'),
            icon: 'text_snippet'
          },
          {
            current: current,
            key: current.id + 'deleteTT',
            action: () => this.openModalForDelete(current),
            title: Helper.getString('delete'),
            img: require('../../../assets/images/trash-icon.svg'),
            style: CommonStyles.deleteText
          },
        ]
      } else {
        menuItems = [
          {
            current: current,
            key: current.id + '-details',
            link: this.baseRoute() + '/orgcourses/' + current.id + '/details',
            title: Helper.getString('edit'),
            icon: 'text_snippet'
          }
        ]
      }
    } else if (Controller.get().roleHelper().isOrgManagerUser()) {
      menuItems = [
        {
          current: current,
          key: current.id + '-details',
          link: this.baseRoute() + '/orgcourses/' + current.id + '/details',
          title: Helper.getString('edit'),
          icon: 'text_snippet'
        }
      ]
    }
    menuItems.map(item => {
      actions.push(UiHelper.customMenuButton(item))
    })
    return actions

  }

  customSubmit(options) {
    const userId = Controller.get().userMgr().getUserId()
    const { formType, formData } = options
    if (formType === 'add') {
      ApiHelper.call({
        method: 'POST', endPoint: ApiHelper.makeUrlPath(['miscs', 'mergedcourse-creation'], {}),
        jsonBody: { ...formData, mode: 'CREATE' }
      },
      (err, data) => {
        if (err) {
          const errorMessage = Helper.getErrorMsg(err)
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: errorMessage,
            success: false,
            message: errorMessage,
            inProcess: false
          })
          return
        } else {
          ApiHelper.call({
            method: 'PUT', endPoint: ApiHelper.makeUrlPath(['orgcourses', data.id]),
            jsonBody: { ownerIds: [userId] }
          }, () => {
            this.setState({ modalOpen: false })
            this.fetchItems()
          })
        }
      })
    }
    if (formType === 'delete') {
      ApiHelper.call({
        method: 'DELETE', endPoint: ApiHelper.makeUrlPath(['orgcourses', this.state.current.id], {}),
        jsonBody: { ...formData }
      },
      (err, data) => {
        if (err) {
          const errorMessage = Helper.getErrorMsg(err)
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: errorMessage,
            success: false,
            message: errorMessage,
            inProcess: false
          })
          return
        } else {
          this.setState({ modalOpen: false })
          this.fetchItems()
        }
      })
    }
  }

}


const Styles = AppConfig.CUSTOM_STYLE ? CustomProductsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 16
  }
})
