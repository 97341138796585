import React, { Component, Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite';
import { Grid, Divider, Dialog, FormControl, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { BaseEntityPage } from '../BaseEntityPage';
import { ApiHelper } from '../../helpers/ApiHelper';
import { AppTheme } from '../../../styles/AppTheme';
import { Helper } from '../../helpers/Helper';
import { SubEntityCard } from '../SubEntity/SubEntityCard';
import { SubEntityList } from '../SubEntity/SubEntityList';
import { UiHelper } from '../../helpers/UiHelper';
import { CommonStyles } from '../../../styles/Styles';
import { Controller } from '../../Controller';
import { AppButton } from '../AppButton';

const feedbacksArr = ['How did this course develop you professionally?', 'Rate your overall experience of the course']

export class OrgCourseEdit extends BaseEntityPage {
  constructor(props, options) {
    super(props)
    this.creatorPurposeOne = options.creatorPurposeOne
    this.pageTitle = Helper.getString('course')
    this.addNewLabel = 'Add'
    this.pageKey = 'Details'
    this.noAdd = true
    this.orgId = (Controller.get().userMgr().isProductSU() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.itemId = this.props.match.params.id
    this.uploadLocation = this.orgId + '/' + this.itemId
    this.emptyText = ' ' // space
    this.apiSpecForImageUploader = {}
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: 'Courses', to: this.baseRoute() + '/courses' },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      {
        key: 'item',
        method: 'GET', endPoint: ApiHelper.makeUrlPath(['miscs', 'courses'], {
          orgId: this.orgId, orgCourseId: this.itemId,
          allvisible: true,
          pb: 'fieldDefCourse,fieldDefCourseSettings,fieldDefCourseConsents,fieldDefCourseFeedbackQuestions,fieldDefDescr'
        })
      },
      { key: 'cats', method: 'GET', endPoint: ApiHelper.makeUrlPath(['ccats'], { orgId: this.orgId, maxPages: 5 }) },
      { key: 'courses', method: 'GET', paths: ['miscs', 'courses'], queryParams: { orgId: this.orgId } },
      { key: 'orgUsers', method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'], { parentOrgId: this.orgId, maxPages: 5 }) },
      { key: 'subOrgs', method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs'], { parentOrgId: this.orgId, maxPages: 5 }) },
    ]
    if (this.creatorPurposeOne) {
      this.toFetch.push(
        {
          key: 'creators', method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'],
            { purposeOne: this.creatorPurposeOne })
        }
      )
    }
    this.apiData = {
    };
    this.handleInputChangeFeedback = this.handleInputChangeFeedback.bind(this);
  }

  onFetchSuccess(results) {
    console.log('onFetchSuccess:', results)
    const item = this.getResult(results, 'item')

    if (Controller.get().roleHelper().disableEditAccess(results[0]) ||
    Controller.get().roleHelper().isOrgManagerUser() &&
    !(Controller.get().userMgr().isProductSU() ||  Controller.get().roleHelper().isOrgSU() || Controller.get().roleHelper().isOrgCreatorUser())) {
      this.hideEditAction = true
      this.hideFormAction = true
    } else if(Controller.get().roleHelper().isOrgCreatorUser() && !(Controller.get().userMgr().isProductSU() ||  Controller.get().roleHelper().isOrgSU())) {
      const appUser = Controller.get().userMgr().getAppUser()
      const userId = appUser.userId
      if (results[0].ownerIds && results[0].ownerIds.indexOf(userId) < 0) {
        this.hideEditAction = true
        this.hideFormAction = true
      }
    }
    this.pageTitle = item.title

    this.fieldDefCourse = item.fieldDefCourse
    this.fieldDefCourse.steps[0].fields = this.fieldDefCourse.steps[0].fields.filter(i => !Helper.inArray(['slug', 'state'], i.key))
    if (item.fieldDefDescr) this.fieldDefCourse.steps[0].fields.push(item.fieldDefDescr.steps[0].fields[0])
    this.fieldDefCourseSettings = item.fieldDefCourseSettings
    this.fieldDefCourseCertification = {
      steps: [
        { fields: item.fieldDefCourseSettings.steps[0].fields.filter(i => Helper.inArray(['certification', 'certificateTemplate'], i.key)) }
      ]
    }
    this.fieldDefCourseCertification.steps[0].fields.forEach(i => i.placement = 'left')

    this.fieldDefCourseAssessment = {
      steps: [
        { fields: item.fieldDefCourseSettings.steps[0].fields.filter(i => Helper.inArray(['assessment', 'assessmentPassPercent'], i.key)) }
      ]
    }
    this.fieldDefCourseAssessment.steps[0].fields.forEach(i => i.placement = 'left')

    this.fieldDefCourseRecurance = {
      steps: [
        {
          fields: item.fieldDefCourseSettings.steps[0].fields.filter(i =>
            Helper.inArray(['recurFrequency', 'recurMaxCount', 'recurEndDate'], i.key))
        }
      ]
    }
    this.fieldDefCourseRecurance.steps[0].fields.forEach(i => i.placement = 'left')

    this.fieldDefPeople = {
      steps: [
        { fields: item.fieldDefCourseSettings.steps[0].fields.filter(i => Helper.inArray(['ownerIds', 'authorIds', 'learnerIds', 'learnerTeamIds'], i.key)) }
      ]
    }
    this.fieldDefPeople.steps[0].fields.forEach(i => i.placement = 'left')

    this.fieldDefCourseSettings.steps[0].fields =
      this.fieldDefCourseSettings.steps[0].fields.filter(i =>
        !Helper.inArray([
          'certification', 'certificateTemplate',
          'assessment', 'assessmentPassPercent',
          'recurFrequency', 'recurMaxCount', 'recurEndDate',
          'ownerIds', 'authorIds', 'learnerIds', 'learnerTeamIds'
        ], i.key))

    this.fieldDefCourseConsents = item.fieldDefCourseConsents
    this.fieldDefCourseFeedbackQuestions = item.fieldDefCourseFeedbackQuestions

    this.cats = this.getResult(results, 'cats')
    this.cats.items = this.cats.items && this.cats.items.filter((item) => item.state === 'LIVE')
    const catsToChoose = Helper.processCats(this.cats.items, true)
    UiHelper.populateOptions(this.fieldDefCourseSettings, { items: catsToChoose }, 'catIds')

    this.courses = this.getResult(results, 'courses')
    UiHelper.populateOptions(this.fieldDefCourseSettings, {
      items: this.courses.items.map(i => { return { id: i.courseId, title: i.title } }).filter(i => i.id !== item.courseId)
    }, 'priorCourses')

    const orgUsers = this.getResult(results, 'orgUsers')
    const keys = {}
    const userItems = []
    orgUsers.items.forEach(i => {
      if (keys[i.userId]) return
      i.id = i.userId // We will keep userId (not the orgUserId)
      keys[i.userId] = i
      userItems.push(i)
    })
    UiHelper.populateOptions(this.fieldDefPeople, { items: userItems }, 'ownerIds')
    UiHelper.populateOptions(this.fieldDefPeople, { items: userItems }, 'authorIds')
    UiHelper.populateOptions(this.fieldDefPeople, { items: userItems }, 'learnerIds')
    const subOrgs = this.getResult(results, 'subOrgs')
    UiHelper.populateOptions(this.fieldDefPeople, { items: subOrgs.items }, 'learnerTeamIds')

    this.consentsTableHeadersMap = {
      'Consent Text': (current) => current.title
    }
    this.feedbackQuestionsTableHeadersMap = {
      Question: (current) => current.title
    }
    this.setState({
      item: item
    })
  }

  renderAddFeedbackDialog = () => {
    const current = {
      "key": "feedbackTitle",
      "type": "edit_text",
      "label": "Question (Answerable on scale of 1 to 10)",
      "hint": "Provide Question",
      "v_required": {
        "value": true,
        "err": "Provide Question"
      },
      "v_min": {
        "value": 2,
        "err": "Question is too short (2 - 100)"
      },
      "v_max": {
        "value": 100,
        "err": "Question is too long (2 - 100)"
      },
      "placement": "left",
      "value": ""
    }

    return (
      <Dialog
        open={this.state.feedbackModal}
        aria-labelledby={'Add'}
        fullWidth={true}
        classes={{
          root: css(Styles.customRoot),
          paper: css(Styles.customPaper),
        }}
        maxWidth='md'
      >
        <div className={css([Styles.modalHeader])}>
          <div className={css(Styles.modalTitle)}>Add</div>
          <button className={css(Styles.modalCloseButton)} onClick={() => this.setState({ feedbackModal: false })} disabled={this.state.inProcess}>
            <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
              <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
            </svg>
          </button>
        </div>
        <div className={css(Styles.modalContent)}>
          <div className={css(Styles.feedbackOptions)}>
            <span className={css(Styles.feedbackExample)}>Few examples</span>
            {feedbacksArr.map((feedback, idx) =>
              <div className={css(Styles.feedbacksContainer)} onClick={() => this.fillSelectedFeedback(feedback)}>
                <span className={css(Styles.feedbackOption)}>{feedback}</span>
                <span className={css(Styles.feedbackSelect)}>Select</span>
              </div>)}

          </div>
          <div className={(css(Styles.incContainer))}>
            <Fragment>
              <FormControl className={css(Styles.formControlMain)} fullWidth >
                {this.renderLabel(current)}
                <TextField
                  autoComplete='off'
                  className={css(Styles.formControl)}
                  fullWidth
                  value={this.state.feedbackTitle}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: css(Styles.textInputStyles),
                      input: css(Styles.formInputStyle),
                    }
                  }}
                  onChange={e => this.handleInputChangeFeedback(e, current.key)} />
              </FormControl>
            </Fragment>
          </div>
          <div className={css(Styles.actions)}>
            <span className={css(Styles.errorMsg)}>
              {this.state.feedbackErrMsg}
            </span>

            <AppButton
              disabled={this.state.inProcess}
              inProcess={this.state.inProcess}
              onClick={this.submitFeedback.bind(this)}
              buttonText={'Save'}
            />
          </div>
        </div>
      </Dialog>
    )
  }

  renderAboveTable() {
    const { item } = this.state
    const { pathname } = this.props.location;
    return (
      <div className={css(Styles.containerNarrow)}>
        {item &&
          <div className={css(CommonStyles.courseActionListContainer)}>
            <div className={css(CommonStyles.courseActionListInnerContainer)}>
              {UiHelper.isOrgClient(pathname) ? UiHelper.courseActionList(this, item, this.pageKey, this.orgId, this.itemId) :
                UiHelper.courseAdminActionList(this, item, this.pageKey, this.itemId)}
            </div>
            <Divider className={css(CommonStyles.courseActionListDivider)} />
          </div>
        }
        <Grid container spacing={4}>
          {item.mode !== 'ADOPT' &&
            <Grid item xs={12} sm={6}>
              <SubEntityCard
                title={Helper.getString('main')} formDefinition={this.fieldDefCourse}
                noEdit={this.hideEditAction ? this.hideEditAction : item.mode === 'ADOPT'}
                item={this.state.item}
                openModalForEdit={(current) => this.openModalForCourseEdit(current, this.fieldDefCourse)}
              />
            </Grid>
          }
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={Helper.getString('settings')} formDefinition={this.fieldDefCourseSettings}
              item={this.state.item}
              openModalForEdit={(current) => this.openModalForOrgCourseEdit(current, this.fieldDefCourseSettings)}
              noEdit={this.hideEditAction}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={Helper.getString('certification')} formDefinition={this.fieldDefCourseCertification}
              item={this.state.item}
              openModalForEdit={(current) => this.openModalForOrgCourseEdit(current, this.fieldDefCourseCertification)}
              noEdit={this.hideEditAction}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={Helper.getString('assessment')} formDefinition={this.fieldDefCourseAssessment}
              item={this.state.item}
              openModalForEdit={(current) => this.openModalForOrgCourseEdit(current, this.fieldDefCourseAssessment)}
              noEdit={this.hideEditAction}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={Helper.getString('recurrence')} formDefinition={this.fieldDefCourseRecurance}
              item={this.state.item}
              openModalForEdit={(current) => this.openModalForOrgCourseEdit(current, this.fieldDefCourseRecurance)}
              noEdit={this.hideEditAction}
            />
          </Grid>
          <Grid item xs={6}>
            <SubEntityList
              title={Helper.getString('consents')}
              noTableHead={true}
              noTableBorder={true}
              tableHeadersMap={this.consentsTableHeadersMap}
              items={Helper.dictToArray(item.consents)}
              subEntityActions={this.consentsSubEntityActions.bind(this)}
              openModalForAdd={(current) => this.openModalForSE({ current, entityAttrName: 'consents', entityAction: 'add' })}
              noAdd={this.hideEditAction}
            />
          </Grid>
          <Grid item xs={6}>
            <SubEntityList
              title={Helper.getString('feedbackConfiguration')}
              noTableHead={true}
              noTableBorder={true}
              tableHeadersMap={this.feedbackQuestionsTableHeadersMap}
              items={Helper.dictToArray(item.feedbackQuestions)}
              subEntityActions={this.feedbackQuestionsSubEntityActions.bind(this)}
              openModalForAdd={(current) => this.openModalForFeedback({ current, entityAttrName: 'feedbackQuestions', entityAction: 'add' })}
              noAdd={this.hideEditAction}
            />
          </Grid>

        </Grid>
        {this.renderAddFeedbackDialog()}
      </div>
    );
  }

  consentsSubEntityActions = (current) => {
    const actions = []
    const menuItems = [
      {
        current: current,
        key: current.id + 'editTT',
        action: () => this.openModalForSE({ current, entityAttrName: 'consents', entityAction: 'edit' }),
        title: Helper.getString('edit'),
        img: require('../../../assets/images/edit-icon.svg')
      },
      {
        current: current,
        key: current.id + 'deleteTT',
        action: () => this.openModalForSE({ current, entityAttrName: 'consents', entityAction: 'del' }),
        title: Helper.getString('delete'),
        img: require('../../../assets/images/trash-icon.svg'),
        style: CommonStyles.deleteText
      },
    ]
    menuItems.map(item => {
      actions.push(UiHelper.customMenuButton(item))
    })

    return actions
  }

  feedbackQuestionsSubEntityActions = (current) => {
    const actions = []
    const menuItems = [
      {
        current: current,
        key: current.id + 'editTT',
        action: () => this.openModalForSE({ current, entityAttrName: 'feedbackQuestions', entityAction: 'edit' }),
        title: Helper.getString('edit'),
        img: require('../../../assets/images/edit-icon.svg')
      },
      {
        current: current,
        key: current.id + 'deleteTT',
        action: () => this.openModalForSE({ current, entityAttrName: 'feedbackQuestions', entityAction: 'del' }),
        title: Helper.getString('delete'),
        img: require('../../../assets/images/trash-icon.svg'),
        style: CommonStyles.deleteText
      },
    ]
    menuItems.map(item => {
      actions.push(UiHelper.customMenuButton(item))
    })

    return actions
  }

  openModalForSE(options) {
    const { current, entityAttrName, entityAction } = options
    if (entityAttrName === 'consents') {
      this.formDefinition = this.fieldDefCourseConsents
    } else if (entityAttrName === 'feedbackQuestions') {
      this.formDefinition = this.fieldDefCourseFeedbackQuestions
    }
    this.formDefinition.formType = entityAction
    if (this.formDefinition.steps[0] && this.formDefinition.steps[0].fields && this.formDefinition.steps[0] && this.formDefinition.steps[0].fields.length > 0) {
      this.formDefinition.steps[0].fields.map((item, index) => {
        this.formDefinition.steps[0].fields[index].value = ''
      })
    }
    if (current) {
      Helper.populateFormDefinitionValues(this.formDefinition, current)
    }

    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction, entityAttrName, id: current ? current.id : undefined },
      apiEndPoint: ApiHelper.makeUrlPath(['orgcourses', this.itemId]),
      modalOpen: true
    })
  }
  openModalForFeedback(options) {
    const { current, entityAttrName, entityAction } = options

    this.formDefinition = this.fieldDefCourseFeedbackQuestions

    this.formDefinition.formType = entityAction
    if (this.formDefinition.steps[0] && this.formDefinition.steps[0].fields && this.formDefinition.steps[0] && this.formDefinition.steps[0].fields.length > 0) {
      this.formDefinition.steps[0].fields.map((item, index) => {
        this.formDefinition.steps[0].fields[index].value = ''
      })
    }
    if (current) {
      Helper.populateFormDefinitionValues(this.formDefinition, current)
    }

    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction, entityAttrName, id: current ? current.id : undefined },
      apiEndPoint: ApiHelper.makeUrlPath(['orgcourses', this.itemId]),
      feedbackModal: true
    })
  }

  handleInputChangeFeedback(event, key) {
    const value = event.target.value
    this.setState({
      feedbackTitle: value
    });
  }
  renderLabel(current) {
    return (
      <div className={css(Styles.labelContainer)}>
        <p className={css(Styles.label)}>{current.label}</p>
        {current && current.v_required && <img className={css(Styles.requiredIndicator)} height='7' width='7' src={require('../../../assets/images/emergency.svg')} />}
      </div>
    )
  }

  fillSelectedFeedback(feedback) {
    this.setState({ feedbackTitle: feedback })
  }



  submitFeedback() {
    if (!this.state.feedbackTitle || this.state.feedbackTitle.length === 0) {
      this.setState({
        feedbackErrMsg: Helper.getString('enterFeedback'),
        inProcess: false
      })
      return
    }
    this.submitApi()
  }

  submitApi() {
    this.setState({
      inProcess: true,
      feedbackErrMsg: ''
    })
    ApiHelper.call({
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath(['orgcourses', this.itemId]),
      jsonBody: {
        entityAction: 'add',
        entityAttrName: 'feedbackQuestions',
        title: this.state.feedbackTitle
      }
    }, (err, data) => {
      if (err) {
        this.setState({
          feedbackErrMsg: Helper.getErrorMsg(err),
          inProcess: false
        })
        return
      } else {
        this.setState({
          feedbackModal: false,
          feedbackErrMsg: '',
          inProcess: false,
          feedbackTitle: ''
        })
        this.fetchItems()
      }
    })
  }



  openModalForCourseEdit(current, fieldDef) {
    this.formDefinition = fieldDef
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onCourseEdit(current);
    this.setState({
      modalOpen: true
    });
  }

  onCourseEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: {},
      apiEndPoint: ApiHelper.makeUrlPath(['courses', current.courseId])
    });
  }

  openModalForOrgCourseEdit(current, fieldDef) {
    this.formDefinition = fieldDef
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onOrgCourseEdit(current);
    this.setState({
      modalOpen: true
    });
  }

  onOrgCourseEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: {},
      apiEndPoint: ApiHelper.makeUrlPath(['orgcourses', current.id])
    });
  }
}

const Styles = StyleSheet.create({
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 50,
    width: '100%',
    '@media (max-width: 767px)': {//TODO: next?
      //width: window.innerWidth - 20 //TODO: fix it, in product screen whitespace nowrap creating issue
    },
  },
  container: {
    textAlign: 'center',
    maxWidth: AppTheme.viewPort,
    marginBottom: 20,
    width: '100%',
  },
  paper: {
    width: '100%',
    padding: 40,
    paddingBottom: 0
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 30,
    width: '100%'
  },
  formControl: {
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #E5E5E5',
    overflow: 'hidden',
    padding: '14px 12px',
    boxSizing: 'border-box'
  },
  incContainer: {
    width: '100%'
  },
  formControlMain: {
    marginTop: 0,
    marginBottom: 0
  },
  feedbackOptions: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginBottom: 40
  },
  feedbacksContainer: {
    display: 'flex',
    width: '100%',
    paddingBottom: 15,
    paddingTop: 15,
    borderBottom: '0.5px solid #d3d3d366',
    cursor: 'pointer'
  },
  feedbackOption: {
    flex: '0.9',
  },
  feedbackSelect: {
    flex: '0.1',
    color: '#4B8BE9',
    cursor: 'pointer'
  },
  labelContainer: {
    display: 'flex',
    marginBottom: 8
  },
  label: {
    fontSize: 14,
    color: 'grey',
    margin: 0,
    display: 'flex',
  },
  requiredIndicator: {
    opacity: 0.5,
    height: 7,
    width: 7
  },
  tooltip: {
    zIndex: 9999999999
  },
  fieldError: {
    color: '#f44336',
    marginTop: 5,
    display: 'block'
  },
  modalHeader: {
    padding: 40,
    paddingBottom: 24
  },
  modalTitle: {
    color: '#121212',
    fontSize: 22,
    fontWeight: 500
  },
  modalCloseButton: {
    position: 'absolute',
    top: 42,
    right: 40,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer'
  },
  modalCloseIcon: {
    width: 18,
    height: 18
  },

  customRoot: {
    backgroundColor: 'rgba(18, 18, 18, 0.2)',
    // backdropFilter: 'blur(10px)',
    zIndex: 13000
  },
  customPaper: {
    borderRadius: 12,
    // minWidth: 600,
    backgroundColor: AppTheme.secondaryBg,
    '::-webkit-scrollbar': {
      display: 'none'
    },
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
  },
  dialogActions: {
    paddingBottom: 40
  },
  modalContent: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  feedbackExample: {
    fontWeight: 'bold'
  },
  errorMsg: {
    paddingRight: 20,
    color: '#f44336'
  }
})