import React, { useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { TitleComponent } from '../components/TitleComponent';
import { DocumentFile } from '../components/DocumentFile';
import { Helper } from '../common/helpers/Helper';
import { AppButton } from '../components/AppButton';
import { AppContext } from '../common/components/contexts/AppContext';
import { CommonStyles } from '../styles/Styles';
import { AppHelper } from '../helpers/AppHelper';
import { FavContext } from '../common/components/contexts/FavContext';

export function ResourcesDetail(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    result: {},
    inProgress: false
  })
  const orgId = Controller.get().roleHelper().getOrgId()
  const resourceId = props.match.params.id
  const { fav, deleteResourcesFromFav, addResourcesToFav } = useContext(FavContext)

  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const courseId = resourceId.split('courseresource').pop().split('-')[0]
      const course = await Controller.get().courseMgr().getCourseSlug(courseId)
      const courseDetails = await Controller.get().courseMgr().getCourseDetails2({orgId, courseSlug: course.slug})
      const fileLink = await Controller.get().courseMgr().getResourceDetail(resourceId, course.slug)
      let selectedResources = {}
      courseDetails.resources.map(item => {
        if (item.id === resourceId) {
          selectedResources = item
        }
      })

      const downloadLink = {
        contentLink: fileLink.link
      }

      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        fileLink: fileLink.link,
        selectedResources: selectedResources,
        downloadLink: downloadLink
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    isPresentInFavList()
  }, [state.selectedResources, fav.resourceData])

  const isPresentInFavList = () => {
    if (fav && fav.resourceData && fav.resourceData.items && fav.favCourseData) {
      for (const f of fav.resourceData.items) {
        if (f.id === resourceId) {
          setState({
            ...state,
            favIcon: true,
            selectedFav: f,
            favLearnerId: fav.favCourseData.favLearnerId
          })
          return
        }
      }
    }
  }

  const toggleResources = async (e) => {
    setState({
      ...state,
      inProgress: true,
    })
    if (state.favIcon) {
      const orgId = Controller.get().roleHelper().getOrgId()
      const id = orgId + '-' + state.favLearnerId + '-favcourse-' + resourceId
      const deleteFav = await deleteResourcesFromFav({ courseResourceId: state.selectedFav.id, id: id })
      if (deleteFav) {
        setState({
          ...state,
          inProgress: false,
          favIcon: false,
        })
      }
    } else {
      const addFav = await addResourcesToFav({ courseResourceId: resourceId })
      if (addFav) {
        setState({
          ...state,
          inProgress: false,
          favIcon: true
        })
      }
    }
  }

  useEffect(() => {
    appContextStateHandler({ pageKey: 'resources' })
    fetchData()
  }, [])

  const titleIcons = [
    // {
    //   imgName: 'share_icon',
    //   onSelected: () => appContextStateHandler({ errorMsgOpen: true })
    // },
    {
      key: 'fav',
      imgName: state.favIcon ? 'heart_selected_icon' : 'heart',
      onSelected: () => toggleResources()
    }
  ]

  const backNav = () => {
    const backNav = props.location && props.location.state && props.location.state
    if(backNav && backNav.courseSlug) {
      return '/learner/orgs/' + orgId + '/course/' + backNav.courseSlug
    } else if(backNav && backNav.favourites) {
      return '/learner/orgs/' + orgId + '/favourites'
    } else {
      return '/learner/orgs/' + orgId + '/resources'
    }
  }

  const backButton = {
    title: Helper.getString('back'),
    link: backNav()
  }

  const downloadFile = () => {
    const fName = AppHelper.getFileName( state.selectedResources)
    if(navigator && navigator.userAgent.startsWith('Azsincx ')) {
      AppHelper.downloadFileForMobileApp(state.fileLink, fName)
    } else {
      AppHelper.downloadFile(state.fileLink)
    }
  }

  return (
    <ScreenWrapper
      state={state}
      titleStyle={CommonStyles.titleStyle}
      logo={true}
      backButton={backButton}>

      <TitleComponent
        title={state.selectedResources && state.selectedResources.title}
        icons={titleIcons}
        preview={props.preview}
        inProgress={state.inProgress} />

      {state.selectedResources &&
      ((state.selectedResources.isDownloadable === 'YES' && state.fileLink) || state.selectedResources.iType === 'FILE') &&
        <div className={css(Styles.buttonOuterContainer)}>
          <AppButton
            onClick={() => downloadFile()}
            buttonContainer={Styles.buttonContainer}
            buttonText={Helper.getString('download')}
            img={'download_icon_light'}
            imgAlt={Helper.getString('downloadImgAlt')}
          />
        </div>
      }
      <div className={css(Styles.fileContainer)}>
        {(state.downloadLink && state.selectedResources.iType !== 'FILE') &&
          <DocumentFile
            data={state.downloadLink} />
        }
      </div>
    </ScreenWrapper>
  )

}

const Styles = StyleSheet.create({
  imgContent: {
    width: 20,
    height: 20,
    marginLeft: 8,
    '@media (max-width: 767px)': {
      width: 16,
      height: 16,
      marginLeft: 4
    },
  },
  buttonOuterContainer: {
    marginTop: 20,
    '@media (max-width: 767px)': {
      marginTop: 24,
    },
  },
  buttonTextContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonContainer: {
    padding: '8px 20px',
    '@media (max-width: 767px)': {
      padding: '8px 15px',
    },
  },
  fileContainer: {
    marginTop: 24,
    '@media (max-width: 767px)': {
      marginTop: 40,
    },
  }
})