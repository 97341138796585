import React, { useEffect, useContext, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Terms } from './Terms';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { Helper } from '../common/helpers/Helper';


export function TermsWrapper(props) {

  return (
    <ScreenWrapper
      logo={true}>
      <Terms learner={true}/>
    </ScreenWrapper>
  )
}

const Styles = StyleSheet.create({

})
