import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme } from '../styles/Styles';

export function Text(props) {

  return (
    <Fragment>
      {props.descr &&
        <p className={css(Styles.content)}>{props.descr}</p>
      }
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  content: {
    margin: AppCommonTheme.pMargin,
    fontSize: 16,
    color: AppCommonTheme.appFontColor,
    whiteSpace: 'pre-wrap',
    '@media (max-width: 767px)': {
      fontSize: 18
    },
    wordBreak:'break-word'
  },
})