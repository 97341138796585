import React, { Fragment, useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme, CommonStyles } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { TitleComponent } from '../components/TitleComponent';
import { AppTheme } from '../styles/AppTheme';
import { Progress } from '../components/Progress';
import { Player } from '@lottiefiles/react-lottie-player';
import { AppContext } from '../common/components/contexts/AppContext';
import { Consents } from '../components/Consents';
import { AppHelper } from '../helpers/AppHelper';

export function Result(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    result: {},
  })
  const [animation, setAnimation] = useState(false)
  const orgId = Controller.get().roleHelper().getOrgId()
  const courseSlug = props.courseSlug ? props.courseSlug : props.match.params.slug
  const coursePassedButtons = []

  async function fetchData() {
    try {
      setState({ ...state, fetchState: ApiHelper.State.LOADING })
      const response = await Controller.get().courseMgr().getCourseDetails2({ orgId, courseSlug })
      setAnimation(true);

      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        result: response,
      })
      if (response.totalAssesmentAnsCount !== response.assessments.length) {
        Controller.get().courseMgr().navToAssessment(courseSlug)
        return
      }

      const intervalId = setInterval(() => {
        setAnimation(false);
      }, 5000);
      return () => clearInterval(intervalId);
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  const openConsent = () => {
    // setState({
    //   ...state,
    //   consentDialogOpen: true,
    // })
    Controller.get().courseMgr().navToConsent(courseSlug)
  }

  // const consentDialogClose = () => {
  //   setState({
  //     ...state,
  //     consentDialogOpen: false,
  //   })
  // }

  // const onSubmitConsent = () => {
  //   setState({
  //     ...state,
  //     consentDialogOpen: false,
  //   })
  //   if (state.result.showFeedback) {
  //     Controller.get().courseMgr().navToFeedback(courseSlug)
  //   }
  // }

  const retakeAssessment = async (processRetake) => {
    if (processRetake) {
      setState({ ...state, inProcessRetake: true })
    } else {
      setState({ ...state, inProcess: true })
    }
    try {
      for (const item in state.result.assessments) {
        const response = await Controller.get().courseMgr().submitAssessment(state.result.assessments[item].id, [], state.result.courseprogs)
      }
      setState({ ...state, inProcess: false, inProcessRetake: false })
      Controller.get().courseMgr().navToAssessment(courseSlug)
    } catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const courseFailButtons = [
    // {
    //   title: Helper.getString('redoCourse'),
    //   onClick: () => appContextStateHandler({ errorMsgOpen: true })
    // },
    {
      title: Helper.getString('retakeAssessment'),
      onClick: () => retakeAssessment()
    }
  ]

  const backButton = {
    title: Helper.getString('back'),
    link: '/learner/orgs/' + orgId + '/course/' + courseSlug
  }

  const headerButtons = () => {
    if(!state.result.coursePassed) {
      return courseFailButtons
    }
    return null
  }

  const nextButton = () => {
    if (state.result.coursePassed) {
      if (state.result.showConsent) {
        return {
          title: Helper.getString('nextConsents'),
          action: () => openConsent()
        }
      } else if (state.result.showFeedback) {
        return {
          title: Helper.getString('nextFeedbacks'),
          action: () => Controller.get().courseMgr().navToFeedback(courseSlug)
        }
      } else if (state.result.showCertification) {
        return {
          title: Helper.getString('nextCertificate'),
          action: () => {
            const aid = AppHelper.getAssignmentId(state.result)
            Controller.get().courseMgr().navToCertificate(courseSlug, { course: true }, aid)
          }
        }
      }
      return null
    } else {
      return null
    }
  }

  return (
    <ScreenWrapper
      state={state}
      subTitle={Helper.getString('assessmentResult')}
      outerContainer={Styles.outerContainer}
      backButton={backButton} >

      {state.result &&
        <Fragment>
          <TitleComponent
            title={state.result.coursePassed ? Helper.getString('coursePassedTitle') : Helper.getString('courseFailedTitle')}
            buttons={headerButtons()}
            nextButton={nextButton()}
            inProcess={state.inProcess} />

          <div className={css(Styles.detailContainer)}>
            {state.result.assessments &&
              <div className={css(CommonStyles.borderContainer, Styles.borderFirstContainer)}>
                <div className={css(Styles.progressOuterContainer)}>
                  <div className={css(Styles.progressContainer)}>
                    <Progress
                      total={state.result.assessments.length}
                      correct={state.result.correctAssessmentQuesCount}
                      value={state.result.percentageValue} />
                  </div>
                  <div className={css(Styles.progressDetailContainer)}>
                    <div className={css(Styles.progressValueContainer)}>
                      <p className={css(Styles.progressValueContent)}>{state.result.correctAssessmentQuesCount} / {state.result.assessments.length}</p>
                      <p className={css(Styles.questionsCorrect)}>{Helper.getString('questionsCorrect')}</p>
                    </div>
                    {/* {state.result.coursePassed &&
                      <div className={css(Styles.retakeOuterContent)}>
                        {state.inProcessRetake ?
                          <Player
                            autoplay
                            loop
                            src={require('../assets/images/circle_loading_progress_indicator.json')}
                            className={css(Styles.processImgContent)} />
                          :
                          <div onClick={() => retakeAssessment(true)}
                            className={css(Styles.refreshImgContainer, Styles.refreshImgContainerDesktop)}>
                            <img
                              alt={Helper.getString('refreshImgAlt')}
                              width='18px' height='18px'
                              src={require('../assets/images/refresh_icon.svg')}
                              className={css(Styles.refreshImgContent)} />
                            <p className={css(Styles.retakeAssessment)}>{Helper.getString('retakeAssessment')}</p>
                          </div>
                        }
                      </div>

                    } */}
                  </div>
                </div>
                {state.result.coursePassed &&
                  <div className={css(Styles.retakeOuterContentMobile)}>
                    {state.inProcessRetake ?
                      <Player
                        autoplay
                        loop
                        src={require('../assets/images/circle_loading_progress_indicator.json')}
                        className={css(Styles.processImgContent)} />
                      :
                      ''
                      // <div onClick={() => retakeAssessment(true)}
                      //   className={css(Styles.refreshImgContainer, Styles.refreshImgContainerMobile)}>
                      //   <img
                      //     alt={Helper.getString('refreshImgAlt')}
                      //     width='18px' height='18px'
                      //     src={require('../assets/images/refresh_icon.svg')}
                      //     className={css(Styles.refreshImgContent)} />
                      //   <p className={css(Styles.retakeAssessment)}>{Helper.getString('retakeAssessment')}</p>
                      // </div>
                    }
                  </div>
                }
              </div>
            }
            {state.result.coursePassed && state.result.reward &&
              <div className={css(CommonStyles.borderContainer, Styles.borderContainer)}>
                <div className={css(Styles.trophyImgOuterContainer)}>
                  {state.result.reward &&
                    <div className={css(Styles.trophyImgContainer)}>
                      <img
                        alt={Helper.getString('trophyImgAlt')}
                        width='344px' height='245px'
                        src={require(`../assets/images/${state.result.reward}_img.svg`)}
                        className={css(Styles.imgContent)} />
                    </div>
                  }
                </div>
                <p className={css(Styles.trophyName)}>{state.result.reward && state.result.reward}</p>
              </div>
            }
          </div>

          {state.result.celebration && animation &&
            <div className={css(Styles.animationContainer)}>
              <Player
                autoplay
                loop
                src={require('../assets/images/animation.json')}
                className={css(Styles.animationContent)} />
            </div>
          }
        </Fragment>
      }

      {/* {state.result && state.result.showConsent &&
        <Consents
          dialogOpen={state.consentDialogOpen}
          onClose={() => consentDialogClose()}
          data={state.result}
          courseprogs={state.result.courseprogs}
          onSubmit={onSubmitConsent}
        />
      } */}
    </ScreenWrapper>
  )

}

const Styles = StyleSheet.create({
  outerContainer: {
    position: 'relative'
  },
  detailContainer: {
    display: 'flex',
    marginTop: 40,
    '@media(max-width: 767px)': {
      flexDirection: 'column'
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      flexDirection: 'column'
    }
  },
  borderFirstContainer: {
    flex: 1,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    '@media(max-width: 1024px)': {
      backgroundColor: AppCommonTheme.bgColor,
      padding: '24px 20px',
    }
  },
  borderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: '20px 24px',
    marginLeft: 20,
    '@media(max-width: 1024px)': {
      marginLeft: 0,
      marginTop: 40
    }
  },
  titleContainer: {
    marginBottom: 5
  },

  trophyImgOuterContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  trophyImgContainer: {
    width: 344,
    height: 245,
    display: 'flex',
    '@media(max-width: 767px)': {
      width: 224,
      height: 160,
    }
  },
  imgContent: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  trophyName: {
    color: AppCommonTheme.appFontColor,
    fontSize: 22,
    margin: AppCommonTheme.pMargin,
    marginTop: 14,
    textAlign: 'center',
    textTransform: 'capitalize',
    '@media(max-width: 767px)': {
      fontSize: 18
    }
  },

  progressOuterContainer: {
    display: 'flex'
  },
  progressContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  progressDetailContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 10
  },
  progressValueContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressValueContent: {
    fontSize: 64,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginBottom: 8,
    '@media (max-width: 767px)': {
      fontSize: 38,
    }
  },
  questionsCorrect: {
    fontSize: 22,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    '@media (max-width: 767px)': {
      fontSize: 16,
    }
  },
  refreshImgContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  refreshImgContent: {
    width: 18,
    height: 18,
    marginRight: 8
  },
  retakeAssessment: {
    fontSize: 16,
    margin: AppCommonTheme.pMargin,
    color: AppTheme.primaryColor,
    textDecoration: 'underline',
    textUnderlineOffset: 1,
  },

  refreshImgContainerDesktop: {
    '@media (max-width: 767px)': {
      display: 'none',
    }
  },
  refreshImgContainerMobile: {
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 25
    }
  },

  animationContainer: {
    position: 'absolute',
    left: '27%',
    top: '37%',
    transform: 'translate(-50%, -50%)',
    margin: 'auto',
  },
  // animationContent: {
  //   width: 500,
  //   height: 500,
  //   '@media(max-width: 767px)': {
  //     width: 300,
  //     height: 300
  //   },
  //   '@media(min-width: 768px) and (max-width: 1024px)': {
  //     width: 400,
  //     height: 400
  //   }
  // }

  processImgContent: {
    width: 32,
    height: 32,
    '@media (max-width: 767px)': {
      width: 28,
      height: 28
    },
  },
  retakeOuterContent: {
    minHeight: 32,
    '@media (max-width: 767px)': {
      display: 'none'
    },
  },
  retakeOuterContentMobile: {
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      minHeight: 44
    }
  }
})